import React, { useContext } from "react";
import Seo from "../components/Seo/Seo";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Box, Button, Text, Title } from "../components/Core";
import { device } from "../utils";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby"
import GlobalContext from "../context/GlobalContext";
import noLogo from "./../assets/image/png/netomnia-circle-logo.png"

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  width: 100%;
  display: block;
  text-align: center;
`;

const BigTitle = styled(Title)`{ 
    font-size:2.8em;
  }`

const SuccessBduk = (props) => {

    const gContext = useContext(GlobalContext);
    const salesStatus = gContext.goGetRegistrationData()?.SearchPremiseResults.properties['NetomniaSalesStatus'];

    const statuses1 = ["RI_INPLANNING_EX_INBUILD", "RI_ONHOLD_EX_INBUILD", "PRE_ORDER", "RI_INPLANNING"];
    const statuses2 = ["REGISTER_INTEREST", "REGISTER_INTEREST_PLUS", "RI_ONHOLD", "I_PHASE3", "RI_LANDLORD", "NULL"];

    const renderTitle = () => {
        if (salesStatus && statuses1.includes(salesStatus)) {
            return "We are currently developing the project in your area!";
        } else if (salesStatus && statuses2.includes(salesStatus)) {
            return "We’re still gaining interest for this BDUK project!";
        } else {
            return "We’re still gaining interest for this BDUK project!";
        }
    }

    const renderSubTitle = () => {
        if (salesStatus && statuses1.includes(salesStatus)) {
            return `
                <p className="text-center mb-5">Thank you for registering your details with us.<p>
                <p className="text-center mb-5">The development in your area is already underway and we will reach out to you when your property is live on our network!</p>
                <p className="text-center mb-5">See the <a href="https://www.netomnia.com/insights/benefits-of-full-fibre-broadband/" target="_blank">benefits of full fibre broadband</a> just so you know what you’ve signed up for!</p>
            `;
        } else if (salesStatus && statuses2.includes(salesStatus)) {
            return `
                <p className="text-center mb-5">Thank you for registering your details with us!<p>
                <p className="text-center mb-5">We are still gaining interest for the BDUK project in your area. We need enough support from your community and interest to get this project moving so please spread the word about us wanting to build you a brand new full fibre network!</p>
                <p className="text-center mb-5">See the <a href="https://www.netomnia.com/insights/benefits-of-full-fibre-broadband/" target="_blank">benefits of full fibre broadband</a> here if you need any extra reasoning!</p>
            `;
        } else {
            return `
                <p className="text-center mb-5">Thank you for registering your details with us!<p>
                <p className="text-center mb-5">We are still gaining interest for the BDUK project in your area. We need enough support from your community and interest to get this project moving so please spread the word about us wanting to build you a brand new full fibre network!</p>
                <p className="text-center mb-5">See the <a href="https://www.netomnia.com/insights/benefits-of-full-fibre-broadband/" target="_blank">benefits of full fibre broadband</a> here if you need any extra reasoning!</p>
            `;
        }
    }

    return (
        <div>
            <Seo
                title={'Netomnia | Success Bduk'}
                description={'Netomnia | Success Bduk'}
            />
            <PageWrapper footerDark>
                <BoxStyled bg="#f7f8fa">
                    <BoxInner className="d-flex align-items-center">
                        <Container>
                            <Box my="150px" mx="auto">
                                <FormStyled>
                                    <div className="justify-content-center">
                                        <Row>
                                            <Col sm={12} lg={12} className="text-center mb-5">
                                                <img src={noLogo} alt="Success" style={{ maxWidth: '80px' }} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} lg={12} className="mx-auto">
                                                <div className="mb-3 pb-2">
                                                    <BigTitle className="mt-3 mb-2 text-center" dangerouslySetInnerHTML={{ __html: renderTitle() }}></BigTitle>
                                                    <div dangerouslySetInnerHTML={{ __html: renderSubTitle() }}></div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </FormStyled>
                            </Box>
                        </Container>
                    </BoxInner>
                </BoxStyled>
            </PageWrapper>
        </div>
    )
}

export default SuccessBduk